import React from 'react';

import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import Link from 'components/ui/link';

import img1 from 'img/help/wordpress/wordpress1.png';
import img2 from 'img/help/wordpress/wordpress2.png';
import img3 from 'img/help/wordpress/wordpress3.png';
import img4 from 'img/help/wordpress/wordpress4.png';
import img5 from 'img/help/wordpress/wordpress5.png';
import img6 from 'img/help/wordpress/wordpress6.png';
import img7 from 'img/help/wordpress/wordpress7.png';
import img8 from 'img/help/wordpress/wordpress8.png';
import img9 from 'img/help/wordpress/wordpress9.png';
import logo from 'img/help/integrations/wordpress.png';

const Content = () => {
  return (
    <div>
      <SubTitle>Installing from WordPress plugin marketplace</SubTitle>
      <p>
        To install the LiveSession WordPress plugin, log in to your WordPress site. Click on plugins
        (1) and Add New (2):
      </p>
      <Image src={img1} lazy alt="Wordpress integration" title="Add new plugin" />
      <p>Use the search field to find the plugin and click the Install button:</p>
      <Image
        src={img2}
        lazy
        alt="Wordpress integration"
        title="Search for LiveSession and click install"
      />
      <p>After the plugin is installed, click on the “Activate” button:</p>
      <Image src={img3} lazy alt="Wordpress integration" title="Activate plugin" />
      <SubTitle>Manual installation</SubTitle>
      <p>
        If you downloaded the plugin from the{' '}
        <Link
          href="https://wordpress.org/plugins/livesession/"
          target="_blank"
          rel="noopener noreferrer"
        >
          WordPress marketplace
        </Link>
        , log in to your WordPress site, click on plugins (1) and Add New (2):
      </p>
      <Image src={img4} lazy alt="Wordpress integration" title="Add new plugin" />
      <p>Click on the Upload plugin button:</p>
      <Image src={img5} lazy alt="Wordpress integration" title="Click on upload" />
      <p>Choose the file you downloaded and click the Install now button:</p>
      <Image src={img6} lazy alt="Wordpress integration" title="Upload plugin" />
      <p>And Activate plugin:</p>
      <Image src={img7} lazy alt="Wordpress integration" title="Activate plugin" />
      <SubTitle>Configuring the plugin</SubTitle>
      <p>To manage the plugin’s settings, go to Settings &gt; LiveSession tab:</p>
      <Image src={img8} lazy alt="Wordpress integration" title="Go to Settings - LiveSession" />
      <p>
        Add LiveSession Track ID (you will find it{' '}
        <Link
          href="https://app.livesession.io/app/settings/websites"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </Link>
        ), root hostname (optional), and decide if you’d like to record keystrokes:
      </p>
      <Image src={img9} lazy alt="Wordpress integration" title="LiveSession plugin setup" />
      <p>After you’re done, click on the Save Changes button.</p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'How to install LiveSession on Wordpress?',
  metaDescription:
    "In this article we'll present how to install our tracking code on WordPress website.",
  canonical: '/help/how-install-on-wordpress/',
  logo,
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped title={frontmatter.metaTitle} related={related} section="Get Started" {...frontmatter} />
);
